<!--
#***********************************************
#
#      Filename: src/views/Detail/FixedVehicles.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 灰名单缴费明细
#        Create: 2021-11-11 14:47:29
# Last Modified: 2021-11-26 16:30:30
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @export="handleExport"
      @filter-change="filterChange"
      @refresh="handleRefresh" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table
      :data="dataList"
      stripe
      border
      size="mini"
      :span-method="objectSpanMethod"
      @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop">
        <template slot-scope="scope">
          {{ parseTime(scope.row.startTime) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop">
        <template slot-scope="scope">
          {{ parseTime(scope.row.endTime) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" />
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop" />
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop" />
    </el-table>
    <!-- end 数据Table -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import moment from 'moment'
  import { list, exportRecord } from '@/api/grayDetail'
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'GrayDetail',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          communityId: '',
          buildingNumber: '',
          areaNumber: '',
          floor: '',
          number: '',
          remark: '',
          unit: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          communityId: [{ required: true, message: '小区名称不能为空', trigger: 'blur' }],
          areaNumber: [{ required: true, message: '区号不能为空', trigger: 'blur' }],
          buildingNumber: [{ required: true, message: '楼号不能为空', trigger: 'blur' }],
          unit: [{ required: true, message: '单元号不能为空', trigger: 'blur' }],
          floor: [{ required: true, message: '楼层号不能为空', trigger: 'blur' }],
          number: [{ required: true, message: '房间号不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: true,
            searchPlaceholder: '姓名'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: false,
            exportDataShow: true,
            importDataShow: false,
            more: []
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'dateRange',
                name: 'dateRange'
              },
              {
                type: 'input',
                name: 'carNumber',
                placeholder: '请输入车牌号'
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '姓名',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'name'
          },
          {
            label: '电话',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'phone'
          },
          {
            label: '车牌号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'carNumber'
          },
          {
            label: '开始时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'startTime'
          },
          {
            label: '结束时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'endTime'
          },
          {
            label: '灰名单费用',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'price'
          },
          {
            label: '缴费类型',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'payTypeCN'
          },
          { label: '车辆类型', isShow: true, align: 'center', prop: 'carTypeCN' }
        ],

        /**
         * 权限信息
         */
        permissions: {
          export: 'graylist:payrecord:export'
        },

        spanArr: [],

        summaries: null
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      /**
       * aaa
       */
      getSpanArr(data) {
        for (var i = 0; i < data.length; i++) {
          if (i === 0) {
            this.spanArr.push(1)
            this.pos = 0
          } else {
            // 判断当前元素与上一个元素是否相同
            if (data[i].phone === data[i - 1].phone) {
              this.spanArr[this.pos] += 1
              this.spanArr.push(0)
            } else {
              this.spanArr.push(1)
              this.pos = i
            }
          }
        }
      },

      /**
       * bbb
       */
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex !== 3) {
          const _row = this.spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          console.log(`rowspan:${_row} colspan:${_col}`)
          return {
            // [0,0] 表示这一行不显示， [2,1]表示行的合并数
            rowspan: _row,
            colspan: _col
          }
        }
      },
      /**
       * 获取列表数据
       */
      getList() {
        const _this = this
        this.loading = true

        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        queryData.pId = this.pid
        this.filterData.searchStr && (queryData.name = this.filterData.searchStr)
        if (this.filterData.dateRange) {
          queryData.startTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
          queryData.endTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
        }
        queryData.carNumber = this.filterData.carNumber

        list(queryData)
          .then((res) => {
            _this.dataList = res.data
            _this.summaries = res.totalRow
            _this.getSpanArr(_this.dataList)
            _this.total = res.total
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
          .finally(() => {
            _this.loading = false
          })
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出灰名单缴费列表?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 组装查询条件
            const queryData = {}
            queryData.pId = this.pid
            queryData.current = this.currentPage
            queryData.size = this.pageSize
            this.filterData.searchKey && (queryData.searchStr = this.filterData.searchKey)
            exportRecord(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'startTime') {
                        return _this.parseTime(v[j])
                      } else if (j === 'endTime') {
                        return _this.parseTime(v[j])
                      } else {
                        return v[j]
                      }
                    })
                  })
                  export_json_to_excel(tHeader, data, '灰名单缴费列表')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
              })
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      }
    }
  }
</script>
