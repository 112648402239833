/*
#***********************************************
#
#      Filename: src/api/blackList/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 灰名单缴费明细
#        Create: 2021-11-23 00:02:38
# Last Modified: 2021-11-26 13:56:29
#***********************************************
*/
import request from '@/utils/request'

// 列表
export function list(data) {
  return request({
    url: '/record/gray/list',
    method: 'get',
    params: data
  })
}

// 导出
export function exportRecord(data) {
  return request({
    url: '/record/gray/export',
    method: 'get',
    params: data
  })
}
